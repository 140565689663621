<template>
  <div class="app-container">
    <el-row class="grid-content bg-purple-dark">
      <el-col :span="20" class="bg-purple-title">{{ $t('title.BasicInformation') }}</el-col>
    </el-row>
    <el-form ref="addOutboundForm" label-width="100px">
      <div>
        <el-row :span="24">
          <el-col :span="8">
            <el-form-item :class="$i18n.locale" label="逻辑仓">
              {{ basicInfor.logicWarehouseName }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :class="$i18n.locale" label="所属物理仓">
              {{ basicInfor.sourceWarehouseName }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :class="$i18n.locale" label="总库存">
              {{ basicInfor.totalQuantity }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :class="$i18n.locale" label="计划库存">
              {{ basicInfor.planQuantity }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :class="$i18n.locale" label="采购在途库存">
              {{ basicInfor.purchaseIntransitQuantity }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :class="$i18n.locale" label="发货在途库存">
              {{ basicInfor.intransitQuantity }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :class="$i18n.locale" label="在库库存">
              {{ basicInfor.inStockQuantity }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :class="$i18n.locale" label="可用库存">
              {{ basicInfor.availableQuantity }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :class="$i18n.locale" label="锁定库存">
              {{ basicInfor.lockQuantity }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :class="$i18n.locale" label="待出库库存">
              {{ basicInfor.awaitOutQuantity }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :class="$i18n.locale" label="不良品库存">
              {{ basicInfor.brokenQuantity }}</el-form-item>
          </el-col>
        </el-row>
      </div>
    </el-form>
    <el-row class="grid-content bg-purple-dark" type="flex">
      <span class="bg-purple-title">{{ $t('title.InventoryDetails') }}</span>
    </el-row>
    <el-form ref="addOutboundForm" :model="outboundForm" label-width="80px">
      <div class="el-lt" style="width:100%;">
        <el-row :span="24">
          <el-col :span="6">
            <el-form-item :class="$i18n.locale" label="SKU">
              <el-input v-model="outboundForm.sku" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :class="$i18n.locale" label="Style">
              <el-input v-model="outboundForm.style" />
            </el-form-item>
          </el-col>
          <el-col :span="1">
            <el-form-item :class="$i18n.locale" label="" />
          </el-col>
          <el-col :span="5">
            <el-button type="primary" @click="_getWarehouseDetail()">{{ $t('page.search') }}</el-button>
            <el-button @click="resetClick()">{{ $t('page.reset') }}</el-button>
          </el-col>
        </el-row>
      </div>
    </el-form>
    <el-table
      ref="tableData"
      v-loading="tableLoading"
      class="mb-3"
      :data="tableData"
      :header-cell-style="{background:'#fafafa'}"
      max-height="550px"
      show-summary
    >
      <el-table-column :label="$t('page.No')" type="index" width="80" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column prop="sku" label="SKU" align="center" />
      <el-table-column prop="style" label="Style" align="center" />
      <el-table-column prop="color" label="Color" align="center" />
      <el-table-column prop="size" label="Size" align="center" />
      <el-table-column prop="totalQuantity" label="总库存" align="center" />
      <el-table-column prop="planQuantity" label="计划库存" align="center">
        <template slot-scope="scope">{{ scope.row.planQuantity }}</template>
      </el-table-column>
      <el-table-column prop="purchaseIntransitQuantity" label="采购在途库存" align="center">
        <template slot-scope="scope">{{ scope.row.purchaseIntransitQuantity }}</template>
      </el-table-column>
      <el-table-column prop="intransitQuantity" label="发货在途库存" align="center">
        <template slot-scope="scope">
          <router-link style="color:#1890ff;" :to="{ path: `/inventory-manage/inventory-inquiry/warehouse/ware-delivery-transit?logicWarehouseCode=${basicInfor.logicWarehouseCode}&row=${JSON.stringify(scope.row)}`}">{{ scope.row.intransitQuantity }}</router-link>
        </template>
      </el-table-column>
      <el-table-column prop="inStockQuantity" label="在库库存" align="center">
        <template slot-scope="scope">{{ scope.row.inStockQuantity }}</template>
      </el-table-column>
      <el-table-column prop="availableQuantity" label="可用库存" align="center">
        <template slot-scope="scope">
          <router-link style="color:#1890ff;" :to="{ path: `/inventory-manage/inventory-inquiry/warehouse/ware-available-inventory?logicWarehouseCode=${basicInfor.logicWarehouseCode}&row=${JSON.stringify(scope.row)}`}">{{ scope.row.availableQuantity }}</router-link>
        </template>
      </el-table-column>
      <el-table-column prop="lockQuantity" label="锁定库存" align="center">
        <template slot-scope="scope">
          <router-link style="color:#1890ff;" :to="{ path: `/inventory-manage/inventory-inquiry/warehouse/ware-lock-inventory?logicWarehouseCode=${basicInfor.logicWarehouseCode}&row=${JSON.stringify(scope.row)}`}">{{ scope.row.lockQuantity }}</router-link>
        </template>
      </el-table-column>
      <el-table-column prop="awaitOutQuantity" label="待出库库存" align="center">
        <template slot-scope="scope">
          <router-link style="color:#1890ff;" :to="{ path: `/inventory-manage/inventory-inquiry/warehouse/ware-outgoing-inventory?logicWarehouseCode=${basicInfor.logicWarehouseCode}&row=${JSON.stringify(scope.row)}`}">{{ scope.row.awaitOutQuantity }}</router-link>
        </template>
      </el-table-column>
      <el-table-column prop="brokenQuantity" label="不良品库存" align="center" />
    </el-table>
    <Paging :pager="pager" @update="update" />
    <el-row class="grid-content bg-blue" type="flex" justify="center">
      <el-button @click="cancle()">{{ $t('title.back') }}</el-button>
    </el-row>
  </div>
</template>
<script>
import Paging from '@/components/Paging'
import { getWarehouseDetail } from '@/api/inventory-inquiry'

export default {
  components: {
    Paging
  },
  data() {
    return {
      basicInfor: {},
      outboundForm: {
        sku: '',
        style: ''
      },
      tableLoading: false,
      pager: {
        size: 100,
        current: 1,
        total: 0
      },
      tableData: []
    }
  },
  computed: {
    queryParams() {
      const { logicWarehouseCode } = this.$route.query
      return Object.assign({}, this.pager, this.outboundForm, { logicWarehouseCode })
    }
  },
  mounted() {
    this._getWarehouseDetail()

    const { row } = this.$route.query
    this.basicInfor = row && JSON.parse(row) || {}
  },
  methods: {
    async _getWarehouseDetail(flag) {
      try {
        this.tableLoading = true
        flag && flag === 1 ? this.pager.current = 1 : ''
        const { datas: { pager, records }} = await getWarehouseDetail(this.queryParams)
        this.pager = pager
        this.tableData = records
      } finally {
        this.tableLoading = false
      }
    },
    update(val) {
      this.pager = val
      this._getWarehouseDetail()
    },
    cancle() {
      this.$router.go(-1)
    },
    resetClick() {
      this.outboundForm = {
        sku: '',
        style: ''
      }
      this._getWarehouseDetail(1)
    }
  }
}
</script>
<style lang="scss" scope>

</style>
